import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { nanoid } from "nanoid";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import SimpleBarReact from "simplebar-react";
import { arrayMoveImmutable } from "array-move";

import { NodeTitle } from "modules/canvas/components/NodeTitle/NodeTitle";
import {
  ChoiceActionTypes,
  NodeDataChildChoice,
  NodeDataChildType,
} from "modules/canvas/data/types";

import useCanvasFunctions from "modules/canvas/useCanvasFunctions";

import InlineTextEdit from "common/components/InlineTextEdit";
import { NodeCard } from "common/components/NodeCard";

import { MainNodeSettingsUI } from "./MainNodeSettings/MainNodeSettings.ui";
import { MainNodeAddBranchUI } from "./MainNodeAddBranch.ui";
import { MainNodeChoiceUI } from "./MainNodeChoice/MainNodeChoice.ui";
import { MainNodeOpenEnded } from "./MainNodeTextResponse.ui";
import { MainNodeAutoAdvance } from "./MainNodeAutoAdvance.ui";

import "../MainNode.scss";

import { currentNodeContext } from "modules/canvas/currentNodeContext";
import { MainNodeNextStepUI } from "./MainNodeNextStep.ui";
import { useVideoModalStore, VIDEO_MODAL_PAGES } from "../MainNode";
import cl from "classnames";
import { IOutcome, useOutComesStore } from "../../OutcomeNode";
import axios from "axios";
import { API_URL } from "modules/canvas/data/apiClient";
import { useCanvasStateStore } from "pages/Canvas";
import { useReactFlow } from "react-flow-renderer";

export const MainNodeUI: React.FC = () => {
  const { data, id, selected } = useContext(currentNodeContext);
  const { saveNodeData } = useCanvasFunctions();
  const rf = useReactFlow();
  const { flowId } = useCanvasStateStore();
  const setPage = useVideoModalStore((state) => state.setPage);
  const [label, setLabel] = useState(data.label ?? "");
  const {
    editMultipleOutCome,
    addOutcomeCta,
    multipleOutComes,
    singleOutcome,
    currentOutcomeType,
    editSingleOutcome,
  } = useOutComesStore();
  const previewVideoRef = useRef<HTMLVideoElement>(null);
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);
  const [thumbnailRefreshKey] = useState(nanoid());

  const fetchVidRef = useRef<NodeJS.Timer | null>(null);

  const isVideoEncoded = useMemo(
    () => Boolean(data.video?.length),
    [data.video?.length]
  );
  const fetchEncodedVideo = useCallback(() => {
    if (data.video) return; // we have already fetched the video URL
    saveNodeData(
      {
        thumbnail: "",
        video: "",
        shouldStartVideoEncoding: false,
      },
      id
    );
    fetchVidRef.current = setInterval(async () => {
      if (!data.videoID || !flowId) return;
      const status = await axios.post(
        `${API_URL}/check-cdn-video-status`,
        new URLSearchParams({ video_id: data.videoID, survey_id: flowId }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: true,
        }
      );
      if (status.data.video_uploaded_to_cdn) {
        const newData = {
          thumbnail: status.data.bunnycdn_thumbnail_link,
          video: status.data.bunnycdn_video_link,
          shouldStartVideoEncoding: false,
        };
        // Clear the timeout
        if (fetchVidRef.current) clearInterval(fetchVidRef.current);

        if (data.isOutcomeNode) {
          if (currentOutcomeType === "multiple")
            editMultipleOutCome(data.rootNodeId, { nodeData: newData });
          else editSingleOutcome({ nodeData: newData });
        }

        saveNodeData(newData, id);
      }
    }, 25000);
  }, [
    currentOutcomeType,
    data.isOutcomeNode,
    data.rootNodeId,
    data.video,
    data.videoID,
    editMultipleOutCome,
    editSingleOutcome,
    flowId,
    id,
    saveNodeData,
  ]);

  useEffect(() => {
    // alert("HERE");
    if (!data.video && data.videoID?.length && data.videoID.length > 0)
      fetchEncodedVideo();
    return () => {
      if (fetchVidRef.current) clearInterval(fetchVidRef.current);
    };
  }, [data.video, data.videoID?.length, fetchEncodedVideo]);

  // useEffect(() => {
  //   if (!data.isOutcomeNode) return;
  //   alert("HERE");
  //   const outcome = getSelectedOutcomesArr().find((o) => o?.id === data.rootNodeId);
  //   saveNodeData(
  //     { video: outcome?.nodeData?.video ?? "", thumbnail: outcome?.nodeData?.thumbnail ?? "" },
  //     data.rootNodeId
  //   );
  // }, [data.isOutcomeNode, data.rootNodeId, getSelectedOutcomesArr, saveNodeData, singleOutcome, multipleOutComes]);

  useEffect(() => {
    setLabel(data.label ?? "");
  }, [data.label]);
  const saveLabel = (val: string) => {
    saveNodeData({ label: val }, id);
    setLabel(val);

    if (data.isOutcomeNode) {
      const newData = { nodeData: { title: val } };
      if (currentOutcomeType === "multiple")
        editMultipleOutCome(data.rootNodeId, newData);
      else editSingleOutcome(newData);
    }
  };

  const saveSmallLabel = (val: string) => {
    saveNodeData({ customSmallLabel: val }, id);
  };

  // CODE FOR OPTIONS DRAG AND DROP
  const DragHandler = useMemo(
    () =>
      SortableHandle(() => (
        <button
          disabled={(data.children as any)?.choices?.length <= 1}
          className="main-node-choice__action"
          aria-label="Reorder Choice"
        >
          <FontAwesomeIcon fixedWidth icon={solid("sort")} />
        </button>
      )),
    [data.children]
  );
  const SortableItem = useMemo(
    () =>
      SortableElement<{ choice: NodeDataChildChoice }>(
        ({ choice }: { choice: NodeDataChildChoice }) => (
          <MainNodeChoiceUI choice={choice} isThumbnailLoaded={isVideoEncoded}>
            <DragHandler />
          </MainNodeChoiceUI>
        )
      ),
    [DragHandler, isVideoEncoded]
  );

  const SortableList = useMemo(
    () =>
      SortableContainer<{ items: NodeDataChildChoice[] }>(
        ({ items }: { items: NodeDataChildChoice[] }) => {
          return (
            <div className="main-node-flow__choice-list">
              {items.map((value, index) => (
                <SortableItem
                  disabled={items.length <= 1}
                  key={`item-${value.id}`}
                  index={index}
                  choice={value}
                />
              ))}
            </div>
          );
        }
      ),
    [SortableItem]
  );

  useEffect(() => {
    // @ts-expect-error
    console.log("CHOICES", data.children.choices);
    // @ts-expect-error
  }, [data.children.choices]);

  return (
    <>
      <div className={"mainNode-wrapper"}>
        {isVideoEncoded && (
          <video
            className={cl(
              "main-node-flow__avatar",
              isVideoEncoded && !data.isWebinarMode && "full"
            )}
            src={data.video}
            poster={data.thumbnail}
            ref={previewVideoRef}
            key={thumbnailRefreshKey}
          />
        )}
        <div className="mainNode-step-title">
          <InlineTextEdit
            inputClass="small-label-input"
            editableValue={data.customSmallLabel ?? ""}
            onSubmit={saveSmallLabel}
          >
            {(edit) => (
              <div className="step-title-input" onClick={edit}>
                <NodeTitle title={data.customSmallLabel ?? ""} />
              </div>
            )}
          </InlineTextEdit>
        </div>

        <NodeCard className={cl("main-node-flow", isVideoEncoded && "overlay")}>
          {selected && <MainNodeSettingsUI />}
          {selected &&
            !data.isOutcomeNode &&
            data.children.type === NodeDataChildType.CHOICES &&
            data.children.choices != null && (
              <MainNodeAddBranchUI
                choices={data.children.choices}
                nodeId={id}
              />
            )}
          {selected && !data.isOutcomeNode && !data.hasTargetNode && (
            // <MainNodeAddStepUI handleAddStepAction={() => addNodeAtEnd(id)} />
            <MainNodeNextStepUI />
          )}

          <div className="main-node-flow__wrapper">
            <SimpleBarReact style={{ height: "420px" }}>
              <div
                className={cl(
                  data.children.type === NodeDataChildType.NONE &&
                    "node-type-none",
                  data.children.type === NodeDataChildType.OPEN_ENDED &&
                    "node-type-open-ended",
                  isVideoEncoded && "video-node"
                )}
              >
                <InlineTextEdit
                  defaultText=""
                  editableValue={label}
                  onSubmit={saveLabel}
                  inputClass="main-node-flow__title-input"
                >
                  {(enableEdit) => (
                    <div onClick={enableEdit} className="main-node-flow__title">
                      {label}
                    </div>
                  )}
                </InlineTextEdit>
                <div className="main-node-flow__content">
                  {!data.videoID && !isVideoEncoded ? (
                    <>
                      <img
                        className="main-node-flow__avatar"
                        src="./avatar.png"
                        alt="avatar"
                      />
                      <button
                        onClick={() => setPage(VIDEO_MODAL_PAGES.ADD_VIDEO)}
                        className="main-node-flow__video-btn"
                      >
                        <FontAwesomeIcon fixedWidth icon={solid("video")} />
                      </button>
                    </>
                  ) : !isVideoEncoded ? (
                    <>
                      <button className="main-node-flow__video-btn">
                        <FontAwesomeIcon
                          fixedWidth
                          icon={duotone("spinner-third")}
                          spin
                        />
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          !isPreviewPlaying
                            ? previewVideoRef.current?.play()
                            : previewVideoRef.current?.pause();
                          setIsPreviewPlaying((p) => !p);
                        }}
                        className="main-node-flow__video-btn play"
                      >
                        {!isPreviewPlaying ? (
                          <FontAwesomeIcon fixedWidth icon={solid("play")} />
                        ) : (
                          <FontAwesomeIcon fixedWidth icon={solid("pause")} />
                        )}
                      </button>
                    </>
                  )}
                </div>
                <div className="main-node-flow__footer">
                  {(data.children.type === NodeDataChildType.CHOICES ||
                    data.children.type === NodeDataChildType.MULTIPLE) && (
                    <div className="main-node-flow__choices">
                      <div className="main-node-flow_btnWrapper">
                        <div className="main-node-flow_line" />
                        <div>
                          <button
                            className="main-node-flow__choice-add-btn"
                            // onClick={(event) => onEdgeClick(event, id)}
                            onClick={() => {
                              if (
                                data.children.type ===
                                  NodeDataChildType.CHOICES ||
                                data.children.type ===
                                  NodeDataChildType.MULTIPLE
                              ) {
                                const choiceId = nanoid();

                                saveNodeData(
                                  {
                                    children: {
                                      ...data.children,
                                      type: data.children.type,
                                      choices: data.children?.choices?.concat([
                                        {
                                          id: choiceId,
                                          value: "Option",
                                          selectedOption:
                                            ChoiceActionTypes.CONTINUE,
                                        },
                                      ]),
                                    },
                                  },
                                  id
                                );
                                if (data.isOutcomeNode) {
                                  addOutcomeCta(data.rootNodeId, {
                                    id: choiceId,
                                    url: "",
                                    value: "Option",
                                  });
                                }
                              }
                            }}
                          >
                            <FontAwesomeIcon fixedWidth icon={solid("plus")} />
                          </button>
                        </div>
                        <div className="main-node-flow_line" />
                      </div>

                      <SortableList
                        items={data.children.choices ?? []}
                        useDragHandle
                        lockAxis="y"
                        onSortEnd={({ newIndex, oldIndex }) => {
                          if (data.isOutcomeNode) {
                            const ctaLabels =
                              currentOutcomeType === "multiple"
                                ? multipleOutComes?.find(
                                    (o) => o.id === data.rootNodeId
                                  )?.nodeData?.ctaLabels ?? []
                                : singleOutcome?.nodeData?.ctaLabels ?? [];
                            const newData: Partial<IOutcome> = {
                              nodeData: {
                                ctaLabels: arrayMoveImmutable(
                                  ctaLabels,
                                  oldIndex,
                                  newIndex
                                ),
                              },
                            };
                            if (currentOutcomeType === "multiple")
                              editMultipleOutCome(
                                data.rootNodeId,
                                newData,
                                true
                              );
                            else editSingleOutcome(newData);
                          }
                          if (
                            data.children.type === NodeDataChildType.CHOICES ||
                            data.children.type === NodeDataChildType.MULTIPLE
                          ) {
                            const c = arrayMoveImmutable(
                              data.children.choices ?? [],
                              oldIndex,
                              newIndex
                            );
                            const newData = {
                              ...data,
                              children: {
                                ...data.children,
                                choices: c,
                              },
                            };
                            rf.setNodes(
                              rf.getNodes().map((node) => {
                                if (node.id === id) node.data = newData;
                                return node;
                              })
                            );
                          }
                        }}
                      />
                      {data.children.type === NodeDataChildType.MULTIPLE && (
                        <div
                          className="main-node-choice continue-btn"
                          style={{ zIndex: 11111 }}
                        >
                          <button className="main-node-choice__label">
                            Continue
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {data.children.type === NodeDataChildType.OPEN_ENDED && (
                    <MainNodeOpenEnded />
                  )}
                  {data.children.type === NodeDataChildType.NONE && (
                    <MainNodeAutoAdvance />
                  )}
                </div>
              </div>
            </SimpleBarReact>
          </div>
        </NodeCard>
      </div>
    </>
  );
};
